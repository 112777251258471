<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">审核管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">班级延期审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编号"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                :disabled="disabled"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top: 10px">
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select
                v-model="delayAuditType"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in delayAuditTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @sort-change="changeSort"
              :default-sort="{ prop: 'auditTime', order: 'ascending' }"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="200"
                fixed
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                width="150"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="200"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeName"
                width="120"
              />
              <el-table-column
                label="开班时间"
                align="left"
                width="120"
                show-overflow-tooltip
                prop="startDate"
              >
                <template slot-scope="scope">
                  {{ scope.row.startDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="原结束时间"
                align="left"
                width="120"
                show-overflow-tooltip
                prop="endDateOld"
              >
                <template slot-scope="scope">
                  {{ scope.row.endDateOld | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="申请结束时间"
                width="120"
                align="left"
                show-overflow-tooltip
                prop="endDateNew"
              >
                <template slot-scope="scope">
                  {{ scope.row.endDateNew | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="申请日期"
                align="center"
                show-overflow-tooltip
                width="180"
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="审核结果"
                align="left"
                show-overflow-tooltip
                prop="delayAuditType"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("DELAY_AUDIT_TYPE", scope.row.delayAuditType)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="备注"
                align="left"
                show-overflow-tooltip
                prop="delayAuditRemark"
              />
              <el-table-column
                label="操作"
                width="200px"
                show-overflow-tooltip
                align="center"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="lookFile(scope.row.projectDelayId)"
                    >查看附件</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="
                      scope.row.delayAuditType == '30' ||
                      scope.row.delayAuditType == '20'
                    "
                    @click="projectPass(scope.row)"
                    >通过</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="
                      scope.row.delayAuditType == '20' ||
                      scope.row.delayAuditType == '30'
                    "
                    @click="reject(scope.row.projectDelayId)"
                    >驳回</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 审核驳回 -->
    <el-dialog
      title="审核事由"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-input
          type="textarea"
          placeholder="请输入审核理由"
          maxlength="100"
          show-word-limit
          v-model="auditReason"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 延期申请附件查看 -->
    <el-dialog title="查看附件" :visible.sync="filedialogVisible" width="30%">
      <el-table
        ref="multipleTable"
        :data="fileList"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
      >
        <el-table-column
          label="文件名称"
          align="center"
          show-overflow-tooltip
          prop="delayFileName"
        />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="jumpLook(scope.row)"
                >查看附件</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import pdf from "pdfobject";
export default {
  name: "operate/CourseRecommen",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      compId: "",
      projectName: "",
      projectCode: "",
      areaId: "",
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      CompanyList: [],
      delayAuditType: "",
      delayAuditTypeList: [],
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      dialogVisible: false,
      filedialogVisible: false,
      fileList: [],
      previewLoding1: false,
      auditReason: "",
    };
  },
  created() {
    this.getareatree();
    this.getclassstatusList();
  },
  computed: {},
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //审核状态获取
    getclassstatusList() {
      const delayAuditTypelist = this.$setDictionary(
        "DELAY_AUDIT_TYPE",
        "list"
      );
      const list = [];
      for (const key in delayAuditTypelist) {
        list.push({
          value: key,
          label: delayAuditTypelist[key],
        });
        this.delayAuditTypeList = list;
      }
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.delayAuditType) {
        params.delayAuditType = this.delayAuditType;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/edu/project/delay/audit/page",
        params,
        pageNum,
      });
    },
    projectPass(row) {
      // this.$post('/edu/project/delay/audit/remind',{projectCode}).then(ret => {
      //   console.log(ret);
      // }).catch(err => {
      //   return
      // })
      // if(row.hasPaper && !row.hasSettle) {
      //    this.$confirm('“该班级有结业考试，审核通过后结业考试时间则置为空，确认通过”，确认则考试时间则为空，否则不允许通过', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //    this.doAjax(row.projectDelayId)
      //   }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消审核'
      //     });
      //   });
      // } else if (row.hasPaper && row.hasSettle) {
      //     this.$confirm('该班级有结业考试，审核通过后结业考试时间则置为空，确认通过,' + '班级已经结算完成，是否确认延期？', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //    this.doAjax(row.projectDelayId)
      //   }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消审核'
      //     });
      //   });
      // }
      if (!row.hasPaper && !row.hasSettle) {
        this.doAjax(row.projectDelayId);
      } else {
        this.doComfirm(row);
      }
    },
    doComfirm(row) {
      let newMessage = "";
      const h = this.$createElement;
      if (row.hasPaper && !row.hasSettle) {
        newMessage =
          "该班级有结业考试，审核通过后结业考试时间则置为空，是否确认延期通过？";
      } else if (row.hasPaper && row.hasSettle) {
        newMessage =
          "该班级有结业考试，审核通过后结业考试时间则置为空;班级已经结算完成，是否确认延期通过？";
      }
      if (!row.hasPaper && row.hasSettle) {
        newMessage = "班级已经结算完成，是否确认延期通过？";
      }
      this.$confirm("提示", {
        title: "提示",
        message: h("div", null, newMessage),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        distinguishCancelAndClose: true,
      })
        .then(() => {
          this.doAjax(row.projectDelayId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    doAjax(projectDelayId) {
      this.$post("/edu/project/delay/audit/adopt", {
        projectDelayId: projectDelayId,
      })
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: "审核成功",
              type: "success",
            });
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    reject(projectDelayId) {
      this.projectDelayId = projectDelayId;
      this.dialogVisible = true;
    },
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        projectDelayId: this.projectDelayId,
        delayAuditRemark: this.auditReason,
      };
      this.$post("/edu/project/delay/audit/reject", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: "成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.auditReason = "";
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.auditReason = "";
      this.dialogVisible = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
    //查看附件
    lookFile(projectDelayId) {
      this.$post("/edu/project/delay/file", { projectDelayId }).then((ret) => {
        console.log(ret);
        this.filedialogVisible = true;
        this.fileList = ret.data || [];
      });
    },
    jumpLook(item) {
      let extension = item.delayFileName.substring(
        item.delayFileName.lastIndexOf(".") + 1
      );
      this.fileType = extension;
      if (this.fileType == "pdf" || this.fileType == "PDF") {
        this.previewLoding1 = true;
        this.$nextTick(() => {
          pdf.embed(item.delayFileKey, "#pdf-cert1");
        });
      } else if (this.fileType == "png" || this.fileType == "jpg") {
        this.previewLoding1 = true;
        this.ImgSrc = item.delayFileKey;
      }
    },
  },
};
</script>
<style lang="less">
.searchbox {
  padding: 0;
}
</style>
